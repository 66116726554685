import { type MetaFunction } from '@remix-run/react';

// Read during bundle-size CI checks
export default function Component() {
  return <div className='text-white'>Blank</div>;
}

export const meta: MetaFunction = () => {
  return [{ title: 'A Blank Page' }];
};
